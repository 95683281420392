<template>
  <el-dialog :visible.sync="visible" @closed="closed" custom-class="v-dialog v-dialog-tire"
    :title="$t('onlineMonitor.tireTitle')" class="dialog-message" :append-to-body="true">
    <div v-if="visibleReal" @closed="closed" class="popTire">
      <div class="form">
        <div class="date"><span>{{$t('onlineMonitor.tireTimes')}}：</span>
          <!-- format="yyyy-MM-dd HH:mm"  -->
          <el-date-picker v-model="formDate" type="daterange" :range-separator="$t('onlineMonitor.tireIner')"
            :start-placeholder="$t('onlineMonitor.tireBegin')" format="yyyy-MM-dd" size="small"
            :end-placeholder="$t('onlineMonitor.tireEnd')">
          </el-date-picker>
        </div>
      </div>
      <div class="content">
        <div class="title"><span>{{$t('onlineMonitor.tirePress')}}</span></div>
        <div class="chart" v-loading="loading">
          <div class="main" id="pressure"></div>
        </div>
        <div class="title t2"><span>{{$t('onlineMonitor.tireTemp')}}</span></div>
        <div class="chart" v-loading="loading">
          <div class="main" id="temperature"></div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
  import miment from 'miment'
  import * as API from '@/api/common'
  import * as G2 from '@antv/g2';
  import {
    sortPostion
  } from '@/common/utils'
  export default {
    data() {
      return {
        miment,
        visible: false,
        loading: true,
        visibleReal: false,
        id: null,
        formDate: [miment(), miment().add(1, 'DD')],
        chartPressure: null,
        chartTemp: null,
      }
    },
    watch: {
      'formDate': {
        deep: true,
        handler() {
          this.fetchData(this.id).then((res) => {
            this.chartTemp.data(res)
            this.chartTemp.render(res)
            this.chartPressure.data(res)
            this.chartPressure.render(res)
          })
        }
      }
    },
    methods: {
      open(id) {
        this.visibleReal = true
        this.visible = true
        this.id = id
        this.fetchData(id).then((res) => {
          let list = res
          if (!list.length) {
            this.$message('没有温度曲线和胎压曲线数据')
            // return
          }
          this.$nextTick(() => {
            this.loadChart_temperature(res)
            this.loadChart_pressure(res)
          })
        })
      },
      closed() {
        this.visibleReal = false
      },
      fetchData(id) {
        this.loading = true
        let startTime = this.formDate[0]
        let endTime = this.formDate[1]
        let startStr = startTime.getFullYear() + '-' + (startTime.getMonth() + 1) + '-' + startTime.getDate()
        let endStr = endTime.getFullYear() + '-' + (endTime.getMonth() + 1) + '-' + endTime.getDate()
        const promise = new Promise(async (resolve) => {
          API.GetVehicleTyreDetails(id, startStr, endStr).then((ret) => {
            let data = ret.data
            let temp = data.map((k) => {
              return k.list
            })
            let arr = []
            temp.map(item => {
              arr.splice(arr.length, 0, ...item)
            })
            let list = sortPostion(arr)
            // console.log(list)
            this.loading = false
            resolve(arr)
          })
        })
        return promise
      },
      loadChart_pressure(data) {
        // let legendItems = [{
        //   name: '个人分数',
        //   // marker: 'square',
        //   // fill: '#FACC14',
        //   // checked: true
        // }, {
        //   name: '平均分数',
        //   // fill: '#000',
        //   // checked: true
        // }]
        // Step 1: 创建 Chart 对象
        this.chartPressure = new G2.Chart({
          container: 'pressure', // 指定图表容器 ID
          autoFit: true,
          width: 520, // 指定图表宽度
          height: 260, // 指定图表高度
          // padding: [20, 0, 20, 50]
        });
        // Step 2: 载入数据源
        this.chartPressure.data(data);
        this.chartPressure.scale({
          RcvTime: {
            tickCount: 4,
            type: 'cat'
          },
          Bar: {
            nice: true,
          },
        });
        this.chartPressure.tooltip({
          showCrosshairs: true,
          shared: true,
        });
        this.chartPressure.axis('Bar', {
          label: {
            formatter: (val) => {
              return val + 'kpa';
            },
          },
        });
        this.chartPressure
          .line()
          .position('RcvTime*Bar')
          .color('TyreNo')
          .tooltip('TyreNo*Bar', (TyreNo, Bar) => {
            return {
              name: TyreNo,
              value: Bar + 'kpa'
            }
          });
        // .shape('smooth');
        this.chartPressure.legend('TyreNo', {
          position: 'top-right',
        });
        //  this.chartPressure.legend({
        //   position: 'top-right',
        //   custom: true,
        //   items: legendItems,
        // });
        this.chartPressure.removeInteraction('legend-filter'); // 移除默认的 legend-filter 数据过滤交互
        this.chartPressure.interaction('legend-visible-filter'); // 使用分类图例的图形过滤
        this.chartPressure.render();
      },
      loadChart_temperature(data) {
        // Step 1: 创建 Chart 对象
        this.chartTemp = new G2.Chart({
          container: 'temperature', // 指定图表容器 ID
          autoFit: true,
          width: 520, // 指定图表宽度
          height: 260, // 指定图表高度
          // padding: [20, 0, 20, 50]
        });
        // Step 2: 载入数据源
        this.chartTemp.data(data);
        this.chartTemp.scale({
          // tick: 3,
          // tickInterval:4,
          RcvTime: {
            // tick: 3,
            tickCount: 4,
            type: 'cat'
            // formatter: (val) => {
            //   console.log(val)
            //   return val;
            // },
          },
          // temperature: {
          //   nice: true,
          // },
        });
        this.chartTemp.tooltip({
          showCrosshairs: true,
          shared: true,
        });
        this.chartTemp.axis('Temperature', {
          label: {
            formatter: (val) => {
              return val + ' °C';
            },
          },
        });
        this.chartTemp
          .line()
          .position('RcvTime*Temperature')
          .color('TyreNo')
          .tooltip('TyreNo*Temperature', (TyreNo, Temperature) => {
            return {
              name: TyreNo,
              value: Temperature + '°C'
            }
          });
        this.chartTemp.legend('TyreNo', {
          position: 'top-right',
        });
        this.chartTemp.removeInteraction('legend-filter'); // 移除默认的 legend-filter 数据过滤交互
        this.chartTemp.interaction('legend-visible-filter'); // 使用分类图例的图形过滤
        this.chartTemp.render();
      }
    }
  }

</script>
<style lang="scss" scoped>
  .form {
    display: flex;

    .data {
      span {
        font-size: 12px;
        color: #444444;
      }
    }

    .group {
      margin-left: 15px;
      line-height: 30px;

      span {
        font-size: 12px;
        color: #444444;
      }
    }
  }

  .content {
    .title {
      margin: 15px 0;
      height: 24px;
      width: 100%;

      span {
        display: block;
        width: 50px;
        height: 24px;
        text-indent: 18px;
        line-height: 24px;
        font-size: 12px;
        color: #fff;
        background-color: #1890ff;
        position: relative;
        z-index: 1;
      }

      span::after {
        content: '';
        display: block;
        width: 24px;
        height: 24px;
        border-radius: 0 24px 24px 0;
        background-color: #1890ff;
        position: absolute;
        top: 0;
        right: -10px;
        z-index: -1;
      }

      span::before {
        content: '';
        display: block;
        height: 6px;
        width: 6px;
        border-radius: 50%;
        background-color: #fff;
        position: absolute;
        top: 9px;
        left: 6px;
        z-index: 1;
      }
    }

    .t2 {
      span {
        background-color: #5ad8a6;
      }

      span::after {
        background-color: #5ad8a6;
      }
    }

    .chart {
      height: 260px;

      // background-color: #444444;
      .main {
        width: 100%;
        height: 100%;
      }

    }


  }

  .el-date-editor {
    width: 260px !important;

    & ::v-deep .el-range-separator {
      width: 20px !important;
    }
  }

</style>
